import React, { useEffect } from 'react'
import HeaderHome from '../dashboard/commons/headerHome'
import heroImage from "../../assets/images/heroImage.png";
import Footer from '../../component/footer';
import twemojiBitingLip from "../../assets/images/twemojiBitingLip.svg"
import fluentCrown from "../../assets/images/fluentCrown.svg"
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import PSOConsultingLogo from "../../assets/images/PSOConsulting.svg"
import whiteBackButton from "../../assets/images/whiteBackButton.svg"
import purpleBackButton from "../../assets/images/purpleBackButton.svg"

const LearnMore = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const { type } = useParams()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onClickNavigate = () => {
    if (type !== "user") {
      navigate("/consultant/registration", { state: { refCode: location?.state?.refCode } });
    } else {
      navigate("/user/registration", { state: { refCode: location?.state?.refCode } })
    }
  };

  return (
    <>
      <div className={`${type !== "user" ? "bg-[#084C5B]" : "bg-[#EAF2F3]"} p-2 lg:p-6 h-[100dvh]`}>
        <div className="w-full bg-[#FFFFFF] h-[80px] rounded-b-full rounded-t-full">
          <div className="flex justify-center pt-[30px] font-bold flex-shrink-0 text-gray-800">
            <img
              src={PSOConsultingLogo}
              alt="logo"
              className="mx-auto w-[183px] h-[26px]"
            />
          </div>
        </div>

        <div className="mt-4 lg:my-8 w-full flex">
          <div className={`py-6 place-content-center ${type !== "user" ? "bg-[#063d49]" : "bg-[#FFFFFF]"} rounded-[25px] p-2 h-full  w-full mx-auto`}>
            <div className="w-full flex items-center ">
              <div
                className="ml-6 mr-auto"
                onClick={() => navigate(`/login`)}
              >
                {type !== "user" ?

                  <img src={whiteBackButton} alt="arrow" />
                  :
                  <img src={purpleBackButton} alt="arrow" />
                }
              </div>

              <div className="mr-auto">
                {type !== "user" ?
                  <>
                    <p className={`w-full text-center pr-10 font-jaldi ${type === "user" ? "text-black" : "text-[#fff]"}  text-[32px] font-semibold`}>
                      Consultants, welcome.
                    </p>
                  </>
                  :
                  <>
                    <p className="w-full font-jaldi text-center text-[32px] font-semibold">Welcome to PSOC!</p>
                    {/* <p className="w-full text-center text-base font-normal">Not a customer? Darlings, click the back button to sign up.</p> */}
                  </>
                }

              </div>
            </div>


            <div className="pt-2 flex flex-col justify-between text-center font-['manrope'] text-[20px] font-extrabold mt-4 h-[calc(100dvh_-_276px)] overflow-y-auto smallScroll px-8">
              <div>
                <div className={`font-bold mb-2 w-full text-center ${type === "user" ? "text-[#6e37be]" : "text-[#C1AEE2]"}`}>GET STARTED </div>

                {type !== "user" ?
                  <>
                    <p className={`${type === "user" ? "text-black" : "text-[#fff]"}`}>
                      An expert in your field? Offer your services directly to your students or anyone requiring consultation on a one-off basis.
                    </p>
                  </>
                  :
                  <>
                    <p className={`${type === "user" ? "text-black" : "text-[#fff]"}`}>
                      PSOConsulting offers direct consultation from experts across industries. We are your go-to site for one on one consultations, without the commitment of engaging a longterm consultant. Sessions are done on a one-off basis, by scheduling an audio, video, or traditional phone call. Welcome to the new way of consultation.
                    </p>
                  </>
                }
              </div>


              {/* <div className={`font-bold w-full text-center ${type === "user" ? "text-[#6e37be]" : "text-[#C1AEE2]"} my-8`}>JOIN US</div> */}

              <div className='w-full flex justify-center mb-3'>
                <button
                  className={`mt-4 px-4 md:px-24 p-2.5 ${type === "user" ? "bg-primary text-white" : "bg-[#FFF] text-[#084C5B]"}  border-[#969696] border rounded h-15 items-center text-left  text-xl font-normal`}
                  onClick={() => onClickNavigate()}
                  autoFocus={false}
                >
                  {type !== "user" ?
                    <>
                      <p className="flex text-lg font-semibold items-center justify-center ">
                        {/* <span className="mr-[15px]">
                        <img src={twemojiBitingLip} alt="twemojiBitingLip" />
                      </span> */}
                        Get Started
                      </p>
                    </>
                    :
                    <>
                      <p className="flex text-lg font-semibold items-center justify-center">
                        {/* <span className="mr-[15px]">
                      <img src={fluentCrown} alt="Crown" />
                    </span> */}
                        Sign Me Up
                      </p>
                    </>
                  }

                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default LearnMore
